<template>
  <div class="wrapper">
    <div class="background-white">
      <div class="container">
        <section class="container-header mb-5">
          <h1 class="container-header--title">
            <icon icon="notifs-sans-orange" size="lg" />
            <span class="text-xl">{{ preferencesTitle }}</span>
          </h1>
          <p
            class="container-header--description">
            Sélectionnez les thèmes qui suscitent votre intérêt ainsi que la manière dont vous préférez être informé.
          </p>
        </section>
      </div>
    </div>
    <div class="background">
      <div>
        <div class="block">
          <span class="text-xl block--title">Gérez vos préférences de notifications</span>
          <section-choices />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionChoices from './blocks/SectionChoices.vue'

export default {
  name: 'Preferences',

  components: {
    SectionChoices
  },

  data: () => ({
    preferencesTitle: 'GESTION DES NOTIFICATIONS'
  })
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply pt-0;
}

.container {
  @apply my-8;

  &-header {
    @apply flex items-center justify-center flex-col;

    &--title {
      @apply flex items-center;
      @apply uppercase font-bold;
    }

    &--description {
      @apply text-sm text-center mt-4;
      max-width: 800px;
    }
  }
}

.block /deep/ .block--title {
  @apply flex items-center justify-center font-bold mt-10;

  .card {
    @apply flex-1 h-full;
  }
}
</style>
