export default {
  SET_NOTIFICATIONS (state, value) {
    state.notifications = value
  },
  SET_NOTIFICATIONS_CATEGORIES (state, value) {
    state.categories = value
  },
  SET_NOTIFICATIONS_PREFERENCES (state, value) {
    state.preferences = value
  },
  RESET_STATE(state) {
    state.notifications = [
      {
        notificationId: null,
        title: null,
        description: null,
        link: null,
        picture: null,
        date: null,
        status: null,
      }
    ]
  },
}
