import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import engineModule from './modules/engine'
import authenticationModule from './modules/authentication'
import suppliersModule from './modules/suppliers'
import subscribersModule from './modules/subscribers'
import categoriesModule from './modules/categories'
import offersModule from './modules/offers'
import documentsModule from './modules/documents'
import articlesModule from './modules/articles'
import contentModule from './modules/content'
import homeModule from './modules/home'
import notificationsModule from './modules/notifications'
import layersModule from './modules/layers'
import IndicesModule from './modules/indices'
import RoadmapModule from './modules/roadmap'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    engine: engineModule,
    suppliers: suppliersModule,
    subscribers: subscribersModule,
    authentication: authenticationModule,
    categories: categoriesModule,
    offers: offersModule,
    documents: documentsModule,
    articles: articlesModule,
    content: contentModule,
    home: homeModule,
    notifications: notificationsModule,
    layers: layersModule,
    indices: IndicesModule,
    roadmap: RoadmapModule,
  },

  state: {
    isNavbarOpen: false,
    isDrawerOpen: false,
    navigationType: null,
    previousRoute: '',
    pagination: 1
  },

  actions,
  mutations,
  getters
})
