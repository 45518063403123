<template>
  <div class="toolbar-notifications">
    <button
      @click="onNotificationsClick">
      <icon
        :icon="hasNotifications ? 'notifs-avec' : 'notifs-sans'"
        size="xl">
      </icon>
    </button>
  </div>
</template>

<script>

export default {
  computed: {
    isOpen() {
      return this.$store.state.layers.isLayerNotificationsOpen
    },

    hasNotifications() {
      return this.$store.state.notifications.notifications?.length > 0
    }
  },

  methods: {
    onNotificationsClick() {
      this.$store.commit('layers/SET_LAYER_NOTIFICATIONS_STATUS', !this.isOpen)
    }
  }
}
</script>
