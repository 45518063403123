const SET_LAYER_NOTIFICATIONS_STATUS = (state, status) => {
  state.isLayerNotificationsOpen = status
}

const RESET_STATE = (state) => {
  state.isLayerNotificationsOpen = false
}

export default {
  SET_LAYER_NOTIFICATIONS_STATUS,
  RESET_STATE,
}
