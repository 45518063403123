<template>
  <header class="toolbar">
    <m-menu />
    <m-logo />
    <m-search />
    <m-hint />
    <m-access />
    <m-notifications />
    <m-user />
  </header>
</template>

<script>
import MMenu from './header/MercurialMenu'
import MLogo from './header/MercurialLogo'
import MSearch from './header/MercurialSearch'
import MHint from './header/MercurialHint'
import MAccess from './header/MercurialAccess'
import MNotifications from './header/MercurialNotifications.vue'
import MUser from './header/MercurialUser'

export default {
  components: {
    MMenu,
    MLogo,
    MSearch,
    MHint,
    MAccess,
    MNotifications,
    MUser,
  }
}
</script>
