import axios from '@/axios'
import store from '@/store'

export default {
  async getNotifications({ commit }){
    try {
      const response = await axios.get(
        '/api/v1/notification'
      )
      commit('SET_NOTIFICATIONS', response.data)
    } catch (error) {
      console.log(error)
    }
  },
  async setReadingStatus({ dispatch }, paraphId) {
    try {
      await axios.post('/api/v1/notification', { paraphId }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': store.state.authentication.xcsrf_token
        }
      })
      await dispatch('getNotifications')
    } catch (error) {
      console.error(error)
    }
  },
  async getNotificationCategories({ commit }){
    try {
      const categories = await axios.get(`${process.env.VUE_APP_API_VERSION}/notification-categories`)
      commit('SET_NOTIFICATIONS_CATEGORIES', categories.data)
    } catch (error) {
      console.error(error)
    }
  },
  async getNotificationPreferences({ commit }){
    try {
      const preferences = await axios.get(`${process.env.VUE_APP_API_VERSION}/notification-preferences`)
      commit('SET_NOTIFICATIONS_PREFERENCES', preferences.data)
    } catch (error) {
      console.error(error)
    }
  },
  async updateNotificationPreferences({ dispatch }, data) {
    try {
      await axios.post(`${process.env.VUE_APP_API_VERSION}/notification-preferences`,
      { 
        userId: data.userId,
        preferences: data.preferences
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': store.state.authentication.xcsrf_token
        }
      })
      await dispatch('getNotificationCategories')
    } catch (error) {
      console.error(error)
    }
  }
}
