import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
  apiKey: "AIzaSyD1FGiZonC91g1nukKbPrSmN8LM-SCECYY",
  authDomain: "notifications-mercurial.firebaseapp.com",
  projectId: "notifications-mercurial",
  storageBucket: "notifications-mercurial.appspot.com",
  messagingSenderId: "1059365844317",
  appId: "1:1059365844317:web:c2fe58d5feac1cf87de4b4",
  measurementId: "G-KBZC6F20SC"
}

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()