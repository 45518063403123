<template>
    <Modal 
      v-if="isModalOpen" 
      :close-button="false"
      @close="closeModal"
    >
      <div :class="['container']">
          <section :class="['container-header', 'mb-5']">
            <div :class="['w-full']">
              <div :class="['scroller', 'overflow-y-auto', 'bg-primary-lighter', 'm-12', 'p-8']">
                <div v-html="modalText"></div>
                <br>
                <br>
              </div>
            </div>
          </section>
      </div>
    </Modal>
</template>

<script>
import axios from '@/axios'
import { useFirebase } from '../../../plugins/utils/notificationFirebase'
import Modal from '@/components/commons/Modal'

export default {
  components: {
    Modal
  },
  data:() => ({
    isModalOpen: false,
    modalText: null,
  }),
  mounted() {
    this.firebaseUtils = useFirebase(axios, this.$messaging)
    this.firebaseUtils.startListeners(this.openModal)
  },

  methods: {
    openModal(payload) {
      this.isModalOpen = true
      this.modalText = "<h1>"+payload.notification.title+"</h1>"
      + "<br>"
      + "<p>"+payload.notification.body+"</p>"
    },
    closeModal() {
      this.isModalOpen = false
    }
  }
}
</script>