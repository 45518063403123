<template>
  <div class="layer layer--right">
    <!-- LAYER HEADER -->
    <div :class="['layer__header']">
      <div class="flex-1">
        <slot name="header" />
      </div>
      <icon
        icon="fermer-blanc"
        size="sm"
        @click.native="$store.commit('layers/RESET_STATE')"
        class="cursor-pointer"
      />
    </div>

    <!-- CONTENUS -->
    <div :class="['layer__content']">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
}
</script>
